import emailjs from 'emailjs-com'
import React, { useState, useRef } from 'react';

const initialState = {
    from_name: '',
    mobile: '',
    adress: '',
}

export const FormPopup = ({ onClose }) => {
    const [{ from_name, mobile, adress }, setState] = useState(initialState);
    const formRef = useRef();
    const handleChange = (e) => {
        const { name, value } = e.target
        setState((prevState) => ({ ...prevState, [name]: value }))
    }

    const validateMobile = (mobile) => {
        const regex = /^\d{10}$/; // Regex for 10 digit mobile number
        return regex.test(mobile);
    };
    const clearState = () => setState({ ...initialState })

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateMobile(mobile)) {
          alert("Please enter a valid 10-digit mobile number.");
          return;
        }
        console.log(from_name, mobile, adress)
        emailjs
            .sendForm(
                'service_156qo9u', 'template_rli3glb', e.target, 'DOFeyEAm_0FtUzs8g'
            )
            .then(
                (result) => {
                    alert('Your Call Back Request has been submitted , you will get a get from our team within 5-10 min. Thanks for your patience')
                    clearState();
                    formRef.current.reset();

                },
                (error) => {
                    console.log(error.text)
                }
            )
    }
    return (
        <div id='contactform' className='popup-overlay'>
            <div className='popup-container'>
                <div className='section-title'>
                    <h2>SUBMIT A CALL BACK REQUEST</h2>
                    <span> <button className="close-btn" onClick={onClose}>
                    &times;
                  </button></span>
                    <p>
                        Please fill out the form below to send us an email and we will
                        get back to you as soon as possible.
                    </p>
               
               
                <form ref={formRef} name='sentMessage' validate onSubmit={handleSubmit}>
                <div>
                <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='from_name'
                        className='form-control'
                        placeholder='Full Name*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                      <input
                        type='number'
                        id='mobile'
                        name='mobile'
                        className='form-control'
                        placeholder='Mobile Number*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                      <div className='form-group'>
                        <textarea
                          name='adress'
                          id='adress'
                          className='form-control'
                          rows='3'
                          placeholder='Address*'
                          required
                          onChange={handleChange}
                        ></textarea>
                        <p className='help-block text-danger'></p>
                      </div>
                      <div id='success'></div>

                    </div>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Get a Call Back
                </button>
                </form>
            </div>
            </div>
        </div>

    )
}